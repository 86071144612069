import React, { useState, useContext, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { UserContext } from '../App';
import axios from 'axios';
import '../style/Account.css';
export default function AccountInfo(props) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [infoData, setInfoData] = useState([]);  // Initialize as an empty array
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const formatSurplus = (value) => {
        return value.toLocaleString('vi-VN');
    };

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setPasswordError("Mật khẩu mới và xác nhận mật khẩu không khớp.");
        } else {
            setPasswordError("");
            try {
                const res = await axios.post(`${API_BASE_URL}/change_password`, {
                    username: user.data[0].UserName,
                    passold: oldPassword,
                    passNew: confirmPassword,
                });
                console.log(res);

                if (res.status === 200) {
                    alert("Mật khẩu đã được thay đổi thành công.");
                    setNewPassword('');
                    setOldPassword('');
                    setConfirmPassword('');
                } else {
                    setPasswordError("Đã có lỗi xảy ra. Vui lòng thử lại sau.");
                }
            } catch (error) {
                setPasswordError(`Error: ${error.response?.data?.error || error.message}`);
            }
        }
    };

    const GetInfo = async () => {
        if (user.data[0].id_type === '1') {
            try {
                const res = await axios.get(`${API_BASE_URL}/get_all_info`);
                console.log('API Response:', res.data); // Log the API response
                
                if (res.status === 200) {
                    // Ensure res.data is an array before setting it

                    setInfoData(res.data.infos);  // Set infoData as the array
                    setFilteredTransactions(res.data.infos);
                }
            } catch (error) {
                console.error("Error fetching info data:", error);
            }
        }
    };

    useEffect(() => {
        GetInfo();
    }, [user]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTransactions(infoData);
        } else {
            setFilteredTransactions(infoData.filter(item =>
                item.UserName.toLowerCase().includes(searchQuery.toLowerCase())
            ));
        }
    }, [searchQuery, infoData]);

    return (
        <>
            <Container>
                <Row>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputUName">Tên tài khoản</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputUName"
                            value={user.data[0].UserName}
                            aria-describedby="passwordHelpBlock"
                            readOnly
                        />
                    </Col>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputSurplus">Số dư <span style={{ visibility: "hidden" }}>Số dư</span></Form.Label>
                        <Form.Control
                            type="text"
                            id="inputSurplus"
                            value={user.data[0].surplus}
                            readOnly
                        />
                    </Col>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputReferralCode">Mã giới thiệu</Form.Label>
                        <Form.Control
                            type="text"
                            id="inputReferralCode"
                            value={user.data[0].UserName}
                            readOnly
                        />
                    </Col>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputOldPassword">Mật khẩu cũ</Form.Label>
                        <Form.Control
                            type="password"
                            id="inputOldPassword"
                            placeholder="Nhập mật khẩu cũ"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </Col>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputNewPassword">Mật khẩu mới</Form.Label>
                        <Form.Control
                            type="password"
                            id="inputNewPassword"
                            placeholder="Nhập mật khẩu mới"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </Col>
                    <Col className="mt-2" xs={12} sm={6} md={4}>
                        <Form.Label htmlFor="inputConfirmPassword">Xác nhận mật khẩu</Form.Label>
                        <Form.Control
                            type="password"
                            id="inputConfirmPassword"
                            placeholder="Xác nhận mật khẩu mới"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            isInvalid={passwordError !== ""}
                        />
                        <Form.Control.Feedback type="invalid">
                            {passwordError}
                        </Form.Control.Feedback>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12} className="d-flex justify-content-end">
                        <Button className="btn-ChangePass" onClick={handlePasswordChange}>
                            Đổi mật khẩu
                        </Button>
                    </Col>
                </Row>
            </Container>
    
            {user.data[0].id_type === '1' && (  // Conditionally render this block
                <div className="container">
                    <h2 className="text-center my-4">THÔNG TIN TÀI KHOẢN</h2>
                    {/* Search Input */}
                    <div className="my-4">
                        <label htmlFor="searchQuery">Tìm kiếm Tài khoản:</label>
                        <input
                            id="searchQuery"
                            type="text"
                            className="form-control"
                            placeholder="Nhập tên tài khoản"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {/* Total Money Table */}
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>TÊN TÀI KHOẢN</th>
                                    <th>EMAIL</th>
                                    <th>SỐ ĐIỆN THOẠI</th>
                                    <th>SỐ DƯ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.map((sMoney) => (
                                    <tr key={sMoney.UserName}>
                                        <td><b>{sMoney.UserName}</b></td>
                                        <td><b>{sMoney.Email}</b></td>
                                        <td><b>{sMoney.PhoneNumber}</b></td>
                                        <td><b>{formatSurplus(sMoney.surplus)}</b></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

        </>
    );
}
