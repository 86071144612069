import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/Login.css';
import ReactLoading from 'react-loading';
import Cookies from 'js-cookie';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage
} from 'mdb-react-ui-kit';
import carouselImage1 from '../image/carousel/coin.png';
import { Fragment } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const Login = ({ onLogin }) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [content, setContent] = useState('');
    const [content1, setContent1] = useState('');
    const navigate = useNavigate();

    useEffect(() => {

        fetchContent();
        
    }, [activeTab]);

    const fetchContent = async () => {
        try {
            const res = await axios.get(`${API_BASE_URL}/getcontents`);
            setContent(res.data.bodydt[0].body || '');
            setContent1(res.data.bodydt[1].body || '');
        } catch (error) {
            console.error('Error fetching content:', error);
            setContent('Lỗi khi tải nội dung.');
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await axios.post(`${API_BASE_URL}/login`, {
                username: username,
                password: password,
            });

            setIsLoading(false);

            if (res.data.length > 0) {
                const key = `${username}_${password}`;
                Cookies.set('key', key, { expires: 1 });
                onLogin(res);
            } else {
                setError('Tên tài khoản hoặc mật khẩu không đúng.');
            }
        } catch (error) {
            setIsLoading(false);
            setError('Đã xảy ra lỗi. Vui lòng thử lại.');
        }
    };

    const handleRegister = () => {
        navigate('/register');
    };

    return (
      <Fragment>
        <MDBRow
          className="justify-content-center align-items-start"
          style={{ minHeight: "100vh", position: "relative" }}
        >
          <MDBCol md={6} className="position-relative">
            <div
              className="background-image"
              style={{
                backgroundImage: `url(${carouselImage1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1,
              }}
            ></div>
            <MDBCard
              className="form-content position-relative"
              style={{ zIndex: 2 }}
            >
              <Box
                sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                  <Tab  style={{ fontWeight: 'bold' }}  label="Đăng Nhập" />
                  <Tab   style={{ fontWeight: 'bold' }} label="Nạp xu không cần tài khoản" />
                </Tabs>
              </Box>

              {activeTab === 0 && (
                <MDBRow className="g-0 d-flex align-items-center">
                  <MDBCol md={12}>
                    <h4   style={{ fontWeight: 'bold' }} className="mt-2 text-center">ĐĂNG NHẬP</h4>
                    <div dangerouslySetInnerHTML={{ __html: content1 }} />
                    <MDBCardBody>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group custom-input-group">
                          <label htmlFor="username">Tên đăng nhập:</label>
                          <input
                            type="text"
                            id="username"
                            className="form-control custom-input"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                        </div>

                        <div className="form-group custom-input-group">
                          <label htmlFor="password">Mật khẩu:</label>
                          <input
                            type="password"
                            id="password"
                            className="form-control custom-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <Link
                            to="/forgot-password"
                            className="form-text"
                            style={{ color: "#f44034" }}
                          >
                            Quên mật khẩu
                          </Link>
                        </div>

                        {!isLoading && (
                          <>
                            <button
                              type="submit"
                              className="login__button-submit"
                            >
                              Đăng nhập
                            </button>
                            <button
                              onClick={handleRegister}
                              className="register-button"
                            >
                              Đăng ký
                            </button>
                          </>
                        )}

                        {isLoading && (
                          <div className="loading-container">
                            <ReactLoading
                              type={"spinningBubbles"}
                              color={"#4E3B31"}
                              height={"10%"}
                              width={"10%"}
                            />
                          </div>
                        )}

                        {error && <div className="error-message">{error}</div>}
                      </form>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              )}

              {activeTab === 1 && (
                <MDBCardBody>
                  <div
                    className="content-display"
                    style={{
                      whiteSpace: "pre-wrap",
                      backgroundColor: "#f9f9f9",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                </MDBCardBody>
              )}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </Fragment>
    );
};

export default Login;