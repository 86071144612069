// CustomSelect.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../style/ListTypeCard.css';


const ListTypeCard = ({ options, selectedValue, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <div className="custom-select">
      <div className="selected" onClick={() => setIsOpen(!isOpen)}>
        {selectedValue
          ? options.find(option => option.value === selectedValue).label
          : "Chọn loại thẻ"}
      </div>
      {isOpen && (
        <ul className="options-list">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleSelect(option.value)}>
              <img src={option.imgSrc} alt={option.label} className="option-icon" />
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ListTypeCard.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default ListTypeCard;
