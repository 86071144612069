import React, { useState, useContext } from "react";
import "../style/rechargeSupme.css";
import { UserContext } from "../App";
import axios from "axios";
import TransactionRecHistory from "./TransactionRe_his";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ListTypeCard from "../components/ListTypeCard";

export default function ScratchCard() {
  const { user } = useContext(UserContext);
  const [pin, setPin] = useState("");
  const [seri, setSeri] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardValue, setCardValue] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const cardTypes = [
    {
      value: "1",
      label: "Viettel",
      imgSrc: require("../image/brands/viettel.jpg"),
    },
    {
      value: "2",
      label: "MobiFone",
      imgSrc: require("../image/brands/mobiphone.jpg"),
    },
    {
      value: "3",
      label: "VinaPhone",
      imgSrc: require("../image/brands/vinaphone.jpg"),
    },
    {
      value: "4",
      label: "Vcoin-VTC Game",
      imgSrc: require("../image/brands/vtc.png"),
    },
    {
      value: "6",
      label: "Garena (chậm)",
      imgSrc: require("../image/brands/garena.webp"),
    },
    {
      value: "14",
      label: "Zing",
      imgSrc: require("../image/brands/zing.webp"),
    },
    { value: "15", label: "Gate", imgSrc: require("../image/brands/gate.png") },
    {
      value: "16",
      label: "Vietnamobile",
      imgSrc: require("../image/brands/vietnammobile.jpg"),
    },
    {
      value: "23",
      label: "Vcoin",
      imgSrc: require("../image/brands/vcoin.webp"),
    },
  ];

  const handleCardTypeChange = (value) => {
    setCardType(value);
  };

  const formatSurplus = (value) => {
    return value?.toLocaleString("vi-VN");
  };

  const handleCardSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(API_BASE_URL + "/card", {
        ApiKey: "de1f089accd3a05d107621f4e0872be1",
        Pin: pin,
        Seri: seri,
        CardType: parseInt(cardType),
        CardValue: parseInt(cardValue),
      });
      if (res.data.code === 1) {
        NotificationManager.success("Nạp tiền thành công", "Thông báo");
        window.location.reload();
      } else {
        NotificationManager.error("Vui lòng thử lại", "Thông báo");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="tab-container container-fluid">
      <div className="row mb-2 gx-2">
        <div className="col-6 text-center">
          <b className="small">Ví tài khoản:</b>
          <b className="text-theme mb-0">
            <span id="moneyMobile">
              {" "}
              {formatSurplus(user?.data[0]?.surplus)}
            </span>
          </b>
        </div>
      </div>

        <div className="container">
            <div className="card border-0 mb-4 shadow-md">
                <div className="card-body bg-none py-3">
                    <div className="marquee-container">
                    <h4 className="marquee">
                        <i className="fas fa-credit-card" aria-hidden="true"></i> Nạp
                        tiền bằng Thẻ cào
                    </h4>
                    </div>
                    <div className="tab-content">
                    {" "}
                    {/* Add the tab-content class here */}
                    <form onSubmit={handleCardSubmit}>
                        <div className="form-group animated-text">
                        <label htmlFor="pin">Mã thẻ</label>
                        <input
                            type="text"
                            id="pin"
                            value={pin}
                            onChange={(e) => setPin(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Nhập mã thẻ"
                        />
                        </div>

                        <div className="form-group animated-text">
                        <label htmlFor="seri">Seri</label>
                        <input
                            type="text"
                            id="seri"
                            value={seri}
                            onChange={(e) => setSeri(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Nhập seri"
                        />
                        </div>

                        <div className="form-group animated-text">
                        <label htmlFor="cardType">Loại thẻ</label>
                        <ListTypeCard
                            options={cardTypes}
                            selectedValue={cardType}
                            onSelect={handleCardTypeChange}
                        />
                        </div>

                        <div className="form-group animated-text">
                        <label htmlFor="cardValue">Mệnh giá</label>
                        <input
                            type="number"
                            id="cardValue"
                            value={cardValue}
                            onChange={(e) => setCardValue(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Nhập mệnh giá"
                        />
                        </div>
                        <button type="submit" className="custom-button animated-text">
                            <i className="fas fa-wallet mx-1"></i>Nạp tiền
                        </button>
                    </form>
                    </div>{" "}
                    {/* End of tab-content */}
                </div>  
            </div>
        </div>

        <div className="container-fluid">
            <TransactionRecHistory userName={user?.data[0]?.UserName} />
            <NotificationContainer />
        </div>
    </div>
  );
}
