// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    color: #4E3B31;
}

.home,
.reports,
.products,
.team,
.reports {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}
html {
    background: linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700);
}
.app_container {
    background: linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;IACI,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,gCAAgC;IAChC,cAAc;AAClB;;AAEA;;;;;IAKI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,8DAA8D;AAClE;AACA;IACI,8DAA8D;AAClE","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');\r\n* {\r\n    box-sizing: border-box;\r\n    margin: 0;\r\n    padding: 0;\r\n    font-family: 'Arial', sans-serif;\r\n    color: #4E3B31;\r\n}\r\n\r\n.home,\r\n.reports,\r\n.products,\r\n.team,\r\n.reports {\r\n    display: flex;\r\n    height: 90vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 3rem;\r\n}\r\nhtml {\r\n    background: linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700);\r\n}\r\n.app_container {\r\n    background: linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
