import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'Thông tin tài khoản',
    path: '/accountinfo',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    arrow: <RiIcons.RiArrowRightSFill />
  
  },
  {
    title: 'Nạp tiền vào ví',
    path: '/',
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    arrow: <RiIcons.RiArrowRightSFill />
    
  },
  {
    title: 'Nạp app',
    path: '/rechargeSupme',
    icon: <FaIcons.FaCartPlus />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Rút tiền',
    path: '/withdrawmoney',
    icon: <IoIcons.IoMdCash  />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Cộng tác viên',
    path: '/collaborator',
    icon: <IoIcons.IoMdPeople />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Thẻ cào',
    path: '/scratchcard',
    icon: <FaIcons.FaCreditCard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Đại lý',
    path: '/agency',
    icon: <IoIcons.IoMdLaptop />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Cài đặt',
    path: '/setting',
    icon: <IoIcons.IoMdSettings />,
    arrow: <RiIcons.RiArrowRightSFill />
  },
  {
    title: 'Thống kê',
    path: '/summary',
    icon: <IoIcons.IoMdStats  />,
    arrow: <RiIcons.RiArrowRightSFill />
  }

];
