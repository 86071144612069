import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactLoading from "react-loading";
import "../style/Transaction_his.css";

const TransactionRecHistory = ({ userName }) => {
  const [transactions, setTransactions] = useState([]);
  const [sum_money, setSum_money] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.UserName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${API_BASE_URL}/get_history_transactionRecharge`,
        {
          params: {
            username: userName,
            limit: 100,
            offset: page * 10,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { transactions, totalCount, sum_money } = res.data;
      setTransactions(transactions);
      setSum_money(sum_money);
      setTotalPages(Math.ceil(totalCount / 10));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setLoading(false);
    }
  };

  const handleClickUpdateCommission = async (userName) => {
    try {
      setLoading(true);
      await axios.post(`${API_BASE_URL}/update_commission`, {
        username: userName,
      });
      setLoading(false);
      const confirm = window.confirm("Đã thanh toán hoa hồng cho " + userName);
      if (confirm) {
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formatSurplus = (value) => {
    return value.toLocaleString("vi-VN");
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };

  const renderPagination = () => {
    const maxPagesToShow = 3; // Số lượng trang tối đa hiển thị cùng lúc
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 0) {
      startPage = 0;
      endPage = Math.min(totalPages - 1, maxPagesToShow - 1);
    }

    if (endPage >= totalPages) {
      endPage = totalPages - 1;
      startPage = Math.max(0, totalPages - maxPagesToShow);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="my-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
            <button className="page-link" onClick={() => handlePageChange(0)}>
              Đầu
            </button>
          </li>
          <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Trước
            </button>
          </li>
          {pageNumbers.map((index) => (
            <li
              key={index}
              className={`page-item ${index === currentPage ? "active" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage >= totalPages - 1 ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Tiếp
            </button>
          </li>
          <li
            className={`page-item ${
              currentPage >= totalPages - 1 ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(totalPages - 1)}
            >
              Cuối
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ReactLoading type="spin" color="#000" />
      </div>
    );
  }
  
  return (
    <>
      {/* <div className="container"> */}
        <h2 className="text-center my-4">Lịch sử giao dịch</h2>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Tìm kiếm theo tên tài khoản"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Tên tài khoản</th>
              <th>Nội dung</th>
              <th>Số tiền</th>
              <th>Hình thức nạp</th>
              <th>Ngày nạp</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.UserName}</td>
                <td>{transaction.Content}</td>
                <td>{formatSurplus(transaction.Money)}</td>
                <td>{transaction.name_type}</td>
                <td>{formatDate(transaction.Date)}</td>
                <td>
                  {transaction.CommissionStatus === 0
                    ? 'Chưa thanh toán'
                    : 'Đã thanh toán'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

        {renderPagination()}
      {/* </div> */}
      {/* <div className="container"> */}
        <h2 className="text-center my-4">Tổng tiền đã nạp</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th >Tên tài khoản</th>
                <th >Tổng Tiền</th>
                <th ></th>
              </tr>
            </thead>
            <tbody>
              {sum_money.map((sMoney) => (
                <tr key={sMoney.UserName}>
                  <td>{sMoney.UserName}</td>
                  <td>{formatSurplus(sMoney.TotalMoney)}</td>
                  {/* <td>
                    <input
                      type="button"
                      value="Thanh Toán"
                      className='button'
                      onClick={() => handleClickUpdateCommission(sMoney.UserName)}
                    />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      {/* </div> */}
    </>
  );
};

export default TransactionRecHistory;
