import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import "../style/styles.css";
import { UserContext } from "../App"; // Import UserContext
import Cookies from "js-cookie";

import coverImage from '../image/carousel/coin.png';

const Nav = styled.div`
  background: ${({ bgColor }) => bgColor};
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: ${({ isLoggedIn }) => (isLoggedIn ? "pointer" : "default")};
  opacity: 1;
`;

const Username = styled.div`
  color: #4e3b31;
  font-size: 1.5rem;
`;

const SidebarNav = styled.nav`
  background: 
  ${({ bgColor }) => {
    // Hàm tách màu đầu tiên
    const extractFirstColor = (gradient) => {
      if (gradient.includes('linear-gradient')) {
        const colors = gradient.match(/#[a-fA-F0-9]{3,6}/g) || [];
        return colors[0] || '#FFFFFF';
      }
      return gradient;
    };
    return extractFirstColor(bgColor);
  }};
  background-size: 250px 300px;
  background-blend-mode: overlay;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  color: #4e3b31;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const LogOut = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  color: #4e3b31;
  cursor: pointer;

  span {
    margin-left: 20px;
  }
`;

const BellIcon = styled.div`
  font-size: 1.5rem;
  margin-left: 20px;
  cursor: pointer;
  color: #4e3b31;
`;

const Sidebar = ({ isOpen, toggleSidebar, isLoggedIn, bgColor }) => {
  const { user } = useContext(UserContext); // Use user data from context
  const { setUser } = useContext(UserContext); // Use setUser from context
  const handleLogout = () => {
    Cookies.remove("key"); // Remove cookie
    setUser(null); // Clear user data
    window.location.href = "/"; // Redirect to login page
  };

  const formatSurplus = (value) => {
    return value.toLocaleString("vi-VN");
  };

  return (
    <IconContext.Provider value={{ color: "#4E3B31" }}>
      <Nav bgColor={bgColor}>
        <NavIcon to="#" isLoggedIn={isLoggedIn}>
          {isLoggedIn && <FaIcons.FaBars onClick={toggleSidebar} />}
        </NavIcon>
        {isLoggedIn && (
          <Username>
          {user.data[0].UserName}
          {/* Conditionally render bell icon only if user.typeid is 1 */}
          {user.data[0].typeid === 1 && (
            <BellIcon>
              <FaIcons.FaBell />
            </BellIcon>
          )}
        </Username>
        )}
      </Nav>
      <SidebarNav sidebar={isOpen}  bgColor={bgColor}>
        <SidebarWrap>
          <NavIcon className="ms-3" to="#" onClick={toggleSidebar}>
            <AiIcons.AiOutlineClose />
            <p  className="justify-content-center text-center w-100">
            <span  className="mt-2" style={{ fontSize: '1.2rem', color: 'red' }}> Đóng ở đây</span>
  </p>
          </NavIcon>
          
          {SidebarData.map(
            (item, index) =>
              // Check for "Liên hệ Admin" and ensure it only shows when id_type is '1'
              // (item.title !== "Thống kê" ||
              //   user?.data[0]?.id_type === "1" ||
              //   user?.data[0]?.check_invite === 1) &&
              (item.title !== "Cài đặt" || user?.data[0]?.id_type === "1") && (
                <SubMenu item={item} key={index} />
              )
          )}
          {isLoggedIn && (
            <LogOut onClick={handleLogout}>
              <AiIcons.AiOutlineLogout  />
              <span>Đăng xuất</span>
            </LogOut>
          )}
        </SidebarWrap>
      </SidebarNav>
    </IconContext.Provider>
  );
};

export default Sidebar;
