import React, { useState, useContext } from "react";
import "../style/rechargeSupme.css";
import { UserContext } from "../App";
import TransactionRecHistory from "./TransactionRe_his";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import logoACB from "../image/brands/acb.webp";

export default function RechargeSupme() {
  const { user } = useContext(UserContext);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const formatSurplus = (value) => {
    return value?.toLocaleString("vi-VN");
  };

  const handleCopy = (text, index) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopiedIndex(index);
          setTimeout(() => setCopiedIndex(null), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy text to clipboard: ", err);
        });
    } else {
      console.error("Clipboard API not supported. Using fallback method.");

      // Fallback: Using execCommand for older browsers or non-HTTPS environments
      try {
        const tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 2000);
        console.log("Text copied using fallback method");
      } catch (err) {
        console.error("Failed to copy text using fallback method: ", err);
      }
    }
  };

  return (
    <div className="tab-container container-fluid">
      <div className="row mb-2 gx-2">
        <div className="col-6 text-center">
          <b className="small">Ví tài khoản:</b>
          <b className="text-theme mb-0">
            <span id="moneyMobile">
              {" "}
              {formatSurplus(user?.data[0]?.surplus)}
            </span>
          </b>
        </div>
      </div>

      <div className="container">
        <div className="card border-0 mb-4 shadow-md">
            <div className="card-body bg-none py-3">
              <div className="card-body">
                {/* Bank Account 1 */}
                <div className="row align-items-center mb-1">
                  <div className="col">
                    <p
                      style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                      className="animated-text"
                    >
                      <span style={{fontWeight:"bold"}}>ACB - NGÂN HÀNG Á CHÂU</span>
                      <img
                        src={logoACB}
                        width="50px"
                        alt=""
                        style={{ marginLeft: "10px", objectFit: "contain" }}
                      />
                    </p>
                  </div>
                </div>

                <p className="mb-1 small animated-text">
                  CTK1:{" "}
                  <span className="text-theme">Nguyễn Dương Quốc Huy</span>
                </p>
                <p className="small mb-1 animated-text">
                  STK1: <span className="text-theme"><b>15706991</b></span>
                  <button
                    onClick={() => handleCopy("15706991", 1)}
                    className="btn btn-link p-0 btn-copy"
                  >
                    {copiedIndex === 1 ? (
                      <>
                        <i className="fas fa-check-circle"></i> Copied!
                      </>
                    ) : (
                      <>
                        <i className="fas fa-copy"></i> Copy
                      </>
                    )}
                  </button>
                </p>
                <p className="small animated-text">
                  NỘI DUNG:{" "}
                  <span className="text-theme">
                    nap {user?.data[0]?.UserName}
                  </span>
                  <button
                    onClick={() =>
                      handleCopy(`nap ${user?.data[0]?.UserName}`, 2)
                    }
                    className="btn btn-link p-0 btn-copy"
                  >
                    {copiedIndex === 2 ? (
                      <>
                        <i className="fas fa-check-circle"></i> Copied!
                      </>
                    ) : (
                      <>
                        <i className="fas fa-copy"></i> Copy
                      </>
                    )}
                  </button>
                </p>
                <hr />
                {/* Bank Account 2 */}
                <p className="mb-1 small animated-text">
                  CTK2: <span className="text-theme">Lưu Thị Hương</span>
                </p>
                <p className="small mb-1 animated-text">
                  STK2: <span className="text-theme"><b>2327888</b></span>
                  <button
                    onClick={() => handleCopy("2327888", 3)}
                    className="btn btn-link p-0 btn-copy"
                  >
                    {copiedIndex === 3 ? (
                      <>
                        <i className="fas fa-check-circle"></i> Copied!
                      </>
                    ) : (
                      <>
                        <i className="fas fa-copy"></i> Copy
                      </>
                    )}
                  </button>
                </p>
                <p className="small animated-text">
                  NỘI DUNG:{" "}
                  <span className="text-theme">
                    nap {user?.data[0]?.UserName}
                  </span>
                  <button
                    onClick={() =>
                      handleCopy(`nap ${user?.data[0]?.UserName}`, 4)
                    }
                    className="btn btn-link p-0 btn-copy"
                  >
                    {copiedIndex === 4 ? (
                      <>
                        <i className="fas fa-check-circle"></i> Copied!
                      </>
                    ) : (
                      <>
                        <i className="fas fa-copy"></i> Copy
                      </>
                    )}
                  </button>
                </p>
                <hr></hr>

                <div className="info-section">
                  <p className="small text-theme animated-text">
                    <strong>Vui lòng đợi 5 giây</strong> sau khi chuyển khoản,
                    tiền sẽ được cộng vào tài khoản của bạn.
                  </p>

                  <p className="small text-warning animated-text">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>Lưu ý:</strong> Hãy chụp lại màn hình sau khi chuyển
                    khoản để đối chiếu nếu tiền chưa vào tài khoản.
                  </p>

                  <p className="small animated-text">
                    - Nếu bạn chuyển sai nội dung, vui lòng liên hệ qua Zalo: 
                    <span className="text-theme"> Huy: <b>0372.000.343</b> </span>
                    để xác thực và cộng tiền thủ công.
                  </p>
                </div>
              </div>
            </div>
        </div>
        <TransactionRecHistory userName={user?.data[0]?.UserName} />
        <NotificationContainer />
      </div>
      <div className="container-fluid">
        
      </div>
    </div>
  );
}
