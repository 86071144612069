import React, { useState } from 'react';
import '../style/Register.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

const Register = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ''; // Default to empty string if not set
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [invite, setInvite] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Mật khẩu không khớp.');
            return;
        }

        try {
            setIsLoading(true);
            await axios.post(`${API_BASE_URL}/register`, {
                username,
                password,
                phone,
                invite,
            });
            setIsLoading(false);
            alert('Tạo tài khoản thành công!');
            navigate('/'); // Redirect to home page
        } catch (error) {
            setIsLoading(false);
            setError('Đăng ký thất bại.');
        }
    };

    const validateUsername = (value) => {
        const regex = /^[a-zA-Z0-9_]+$/;
        if (!regex.test(value)) {
            setError('Tên đăng nhập không được có dấu và không có khoảng trắng');
            setUsername(''); // Clear the username state if invalid
        } else {
            setError(''); // Clear the error if valid
            setUsername(value.toLowerCase());
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        validateUsername(value);
    };

    return (
        <>
            {isLoading ? (
                <div className="loading-container">
                    <ReactLoading type={'spinningBubbles'} color={'#4E3B31'} height={'10%'} width={'10%'} />
                </div>
            ) : (
                <div className="register-container">
                    {error && <p className="error-message">{error}</p>}
                    <h2 className="register-heading">Đăng ký</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="phone">Số điện thoại:</label>
                            <input
                                type="tel"
                                id="phone"
                                placeholder="Nhập số điện thoại"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username">Tên đăng nhập:</label>
                            <input
                                type="text"
                                id="username"
                                placeholder="Nhập tên đăng nhập"
                                value={username}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mật khẩu:</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Nhập mật khẩu"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm-password">Xác nhận mật khẩu:</label>
                            <input
                                type="password"
                                id="confirm-password"
                                placeholder="Nhập lại mật khẩu"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="invite">Mã giới thiệu:</label>
                            <input
                                type="text"
                                id="invite"
                                placeholder="Nhập mã giới thiệu"
                                value={invite}
                                onChange={(e) => setInvite(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="submit-button" disabled={isLoading}>
                            Đăng ký
                        </button>
                    </form>
                </div>
            )}
        </>
    );
};

export default Register;
