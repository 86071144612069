import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import { UserContext } from '../App'; // Giả sử bạn có UserContext để lấy thông tin người dùng
import ReactLoading from 'react-loading';
import "../style/WithdrawMoney.css";

export default function WithdrawMoney() {
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext); // Lấy thông tin người dùng từ context
    const [accountNumber, setAccountNumber] = useState(''); // Số tài khoản
    const [accountName, setAccountName] = useState(''); // Tên tài khoản
    const [amount, setAmount] = useState(''); // Số tiền rút
    const [password, setPassword] = useState(''); // Mật khẩu xác nhận
    const [errors, setErrors] = useState({}); // Lưu thông báo lỗi
    const [message, setMessage] = useState(''); // Thông báo thành công
    const [refunds, setRefunds] = useState([]); // Lưu trữ các khoản rút tiền nếu user.type_id = 1
    const [activeTab, setActiveTab] = useState('chua-chuyen'); // Quản lý tab hiện tại

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    
    useEffect(() => {
        if (user.data[0].id_type === "1") {
            fetchRefunds();
        }
    }, [user]);

    const fetchRefunds = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_BASE_URL + '/getRefund');
            setRefunds(response.data.refunds);
        } catch (error) {
            setMessage(`Lỗi khi tải dữ liệu: ${error.response?.data?.error || error.message}`);
        }
        setLoading(false);
    };

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
    };

    const handleAccountNameChange = (e) => {
        setAccountName(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Xác thực đầu vào
        const errors = {};
        if (!accountNumber) errors.accountNumber = 'Số tài khoản là bắt buộc.';
        if (!accountName) errors.accountName = 'Tên tài khoản là bắt buộc.';
        if (!amount || isNaN(amount) || amount <= 0) errors.amount = 'Số tiền rút phải là một số dương.';
        if (!password) errors.password = 'Mật khẩu xác nhận là bắt buộc.';
        if (parseFloat(amount) > parseFloat(user?.data[0]?.surplus)) errors.amount = 'Số tiền rút vượt quá số dư hiện tại.';

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setLoading(false);
            return;
        }

        // Gửi yêu cầu rút tiền
        try {
            const response = await axios.post(API_BASE_URL + '/insertRefund', {
                userName: user.data[0].UserName,
                accountNumber,
                accountName,
                money: amount,
                passW: password
            });
            setMessage('Rút tiền thành công!');
            // Xóa trường nhập
            setAccountNumber('');
            setAccountName('');
            setAmount('');
            setPassword('');
            setErrors({});
        } catch (error) {
            setMessage(`Lỗi: ${error.response?.data?.error || error.message}`);
        }
        setLoading(false);
    };

    const handleUpdateRefund = async (id) => {
        try {
            await axios.post(API_BASE_URL + '/updateRefund', { id });
            setMessage('Cập nhật thành công!');
            fetchRefunds(); // Refresh the list of refunds
        } catch (error) {
            setMessage(`Lỗi khi cập nhật: ${error.response?.data?.error || error.message}`);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <ReactLoading type="spin" color="#000" />
            </div>
        );
    }

    const filteredRefunds = refunds.filter(refund => 
        activeTab === 'chua-chuyen' ? refund.status === 0 : refund.status !== 0
    );

    return (
        <div className="container mt-5">
            {user.data[0].id_type === "1" ? (
                <>
                    <h1 className="mb-4 text-center font-weight-bold">DANH SÁCH RÚT TIỀN</h1>
                    <div className="mb-4">
                        <button 
                            className={`btn ${activeTab === 'chua-chuyen' ? 'btn-primary' : 'btn-secondary'}`} 
                            onClick={() => setActiveTab('chua-chuyen')}
                        >
                            Chưa chuyển
                        </button>
                        <button 
                            className={`btn ${activeTab === 'da-chuyen' ? 'btn-primary' : 'btn-secondary'}`} 
                            onClick={() => setActiveTab('da-chuyen')}
                        >
                            Đã chuyển
                        </button>
                    </div>
                    <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>UserName</th>
                                    <th>Money</th>
                                    <th>Request Date</th>
                                    <th>Account Number</th>
                                    <th>Name Bank</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRefunds.map((refund) => (
                                    <tr key={refund.id}>
                                        <td>{refund.id}</td>
                                        <td>{refund.UserName}</td>
                                        <td>{refund.Money}</td>
                                        <td>{refund.RequesDate}</td>
                                        <td>{refund.AccNumber}</td>
                                        <td>{refund.NameBank}</td>
                                        <td>
                                            {refund.status === 0 ? (
                                                <button 
                                                    className="btn btn-warning"
                                                    onClick={() => handleUpdateRefund(refund.id)}
                                                >
                                                    Đã chuyển
                                                </button>
                                            ) : (
                                                <button className="btn btn-success">Đã hoàn thành</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {message && <div className="mt-3 alert alert-info">{message}</div>}
                    </div>
                </>
            ) : (
                <>
                    <h1 className="mb-4 text-center font-weight-bold">RÚT TIỀN</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="accountNumber" className="form-label">Số tài khoản ngân hàng:</label>
                            <input
                                type="text"
                                id="accountNumber"
                                className="form-control"
                                value={accountNumber}
                                onChange={handleAccountNumberChange}
                                placeholder="Nhập số tài khoản"
                            />
                            {errors.accountNumber && <div className="text-danger">{errors.accountNumber}</div>}
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="accountName" className="form-label">Tên tài khoản ngân hàng:</label>
                            <input
                                type="text"
                                id="accountName"
                                className="form-control"
                                value={accountName}
                                onChange={handleAccountNameChange}
                                placeholder="Nhập tên tài khoản"
                            />
                            {errors.accountName && <div className="text-danger">{errors.accountName}</div>}
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="amount" className="form-label">Số tiền:</label>
                            <input
                                type="number"
                                id="amount"
                                className="form-control"
                                value={amount}
                                onChange={handleAmountChange}
                                placeholder="Nhập số tiền rút"
                            />
                            {errors.amount && <div className="text-danger">{errors.amount}</div>}
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="password" className="form-label">Mật khẩu xác nhận:</label>
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Nhập mật khẩu xác nhận"
                            />
                            {errors.password && <div className="text-danger">{errors.password}</div>}
                        </div>
                        <button type="submit" className="btn btn-primary">Rút tiền</button>
                    </form>
                    {message && <div className="mt-3 alert alert-info">{message}</div>}
                </>
            )}
        </div>

    );
}
