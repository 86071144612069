import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactLoading from 'react-loading';
import "../style/Transaction_his.css";

const TransactionHistory = ({ userName }) => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchTransactions = async (page) => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_BASE_URL}/get_history_transaction`, {
        params: {
          username: userName,
          limit: 150,
          offset: page * 10,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const { transactions, totalCount } = res.data;
      console.log("Fetched Transactions:", transactions);  // In ra kết quả trả về từ API để kiểm tra
      setTransactions(transactions);
      setTotalPages(Math.ceil(totalCount / 10));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage);
  }, [currentPage]);

  // Chuyển tất cả các tên tài khoản và từ khóa tìm kiếm về chữ thường để so sánh chính xác
  const filteredTransactions = transactions.filter((transaction) => {
    const userName = transaction.UserName ? transaction.UserName.toLowerCase().trim() : '';
    const searchQuery = searchTerm.toLowerCase().trim();
    return userName.includes(searchQuery);
  });

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formatSurplus = (value) => {
    return value.toLocaleString('vi-VN');
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    return `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };

  const renderPagination = () => {
    const maxPagesToShow = 10; // Số lượng trang tối đa hiển thị cùng lúc
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage < 0) {
      startPage = 0;
      endPage = Math.min(totalPages - 1, maxPagesToShow - 1);
    }

    if (endPage >= totalPages) {
      endPage = totalPages - 1;
      startPage = Math.max(0, totalPages - maxPagesToShow);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="my-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(0)}>
              First
            </button>
          </li>
          <li className={`page-item ${currentPage === 0 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
              Previous
            </button>
          </li>
          {pageNumbers.map((index) => (
            <li key={index} className={`page-item ${index === currentPage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(index)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${currentPage >= totalPages - 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </button>
          </li>
          <li className={`page-item ${currentPage >= totalPages - 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(totalPages - 1)}>
              Last
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ReactLoading type="spin" color="#000" />
      </div>
    );
  }

  return (
    <div className="container">
      <h2 className="text-center my-4">Lịch sử giao dịch</h2>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Tìm kiếm theo tên tài khoản"
          value={searchTerm}
          onChange={(e) => {
            const input = e.target.value;
            console.log("Search Term:", input);  // In ra giá trị tìm kiếm mỗi khi người dùng gõ
            setSearchTerm(input);
          }}
        />
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Tên tài khoản</th>
              <th>Tên app</th>
              <th>ID app</th>
              <th>Tiền</th>
              <th>Đơn giá</th>
              <th>Tổng xu nhận</th>
              <th>Thời gian</th>
              <th>Số dư</th>
              <th>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
  {filteredTransactions.length === 0 ? (
    <tr>
      <td colSpan="9" className="text-center">Không tìm thấy kết quả.</td>
    </tr>
  ) : (
    filteredTransactions.map((transaction,index) => (
      <tr key={index}>  {/* Cập nhật key để đảm bảo duy nhất */}
        <td>{transaction.UserName}</td>
        <td>{transaction.Name_app}</td>
        <td>{transaction.numberID}</td>
        <td>{formatSurplus(transaction.Money)}</td>
        <td>{transaction.price}</td>
        <td>{transaction.sum_coin}</td>
        <td>{formatDate(transaction.time)}</td>
        <td>{transaction.surplus ? formatSurplus(transaction.surplus) : ''}</td>
        <td>{transaction.Status}</td>
      </tr>
    ))
  )}
</tbody>
        </table>
      </div>
      {renderPagination()}
    </div>
  );
};

export default TransactionHistory;
