import React, { useState, useContext, useEffect, useCallback } from "react";
import "../style/rechargeSupme.css";
import { UserContext } from '../App';
import axios from "axios";
import { Combobox } from 'react-widgets';
import ReactLoading from 'react-loading';
import 'react-widgets/dist/css/react-widgets.css';
import TransactionHistory from "./TransactionApp_his";

export default function RechargeSupme() {
  const [activeTab, setActiveTab] = useState("nap-tien");
  const [_app, setApp] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [customText, setCustomText] = useState('');
  const [coint, setCoint] = useState(0);
  const [appId, setAppId] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { user } = useContext(UserContext);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch application data
  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`${API_BASE_URL}/allapp`);
      if (res.data.length > 0) {
        setApp(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching app data:', error);
      setLoading(false);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const handleChange = useCallback((value) => {
    setSelectedItem(value);
    setCoint(Number(customText * value.price));
  }, [customText]);

  const handleTextChange = useCallback((event) => {
    const rawValue = event.target.value.replace(/[^\d]/g, "");
    setCustomText(rawValue);
    setCoint(Number(rawValue * selectedItem?.price || 0));
  }, [selectedItem]);

  const handleAppIdChange = useCallback((event) => {
    setAppId(event.target.value);
  }, []);

  const formatNumber = (value) => {
    return value ? new Intl.NumberFormat("vi-VN").format(value) : "";
  };

  const formatSurplus = (value) => {
    return value.toLocaleString('vi-VN');
  };

  const validateFields = () => {
    const newErrors = {};
    if (!selectedItem) newErrors.selectedItem = 'Bạn cần chọn ứng dụng.';
    if (!appId) newErrors.appId = 'Bạn cần nhập ID app của bạn.';
    if (!customText) newErrors.customText = 'Bạn cần nhập số tiền cần nạp.';
    if (coint <= 0) newErrors.coint = 'Số tiền nạp không hợp lệ.';
    if (parseFloat(customText) > parseFloat(user?.data[0]?.surplus)) {
      newErrors.customText = 'Số tiền cần nạp vượt quá số dư hiện tại.';
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    setErrors({});
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const confirmed = window.confirm('Bạn chắc chắn muốn gửi?');
    if (!confirmed) return;

    setLoading(true);
    try {
      const res = await axios.post(`${API_BASE_URL}/add_transaction`, {
        UserName: user?.data[0]?.UserName,
        Money: parseFloat(customText),
        id_app: selectedItem.Id_app,
        time: new Date().toISOString(),
        numberID: appId,
        surplus: user?.data[0]?.surplus
      });

      user.data[0].surplus = user.data[0].surplus - parseFloat(customText);
      setLoading(false);


        window.location.reload();

    } catch (error) {
      console.error('Error adding transaction:', error);
      setErrors({ submit: 'Failed to add transaction' });
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ReactLoading type="spin" color="#000" />
      </div>
    );
  }

  return (
    <div className="tab-container container-fluid">
      <div className="row mb-2 gx-2">
        <div className="col-6 text-center">
          <b className="small">Ví tài khoản:</b>
          <b className="text-theme mb-0">
            <span id="moneyMobile">{formatSurplus(user?.data[0]?.surplus)}</span>
          </b>
        </div>
        <div className="col-6 text-center">
          <b className="small">Hoa hồng:</b>
          <b className="text-theme mb-0">
            <span id="moneyRefMobile">0</span>
          </b>
        </div>
      </div>

      <div className="card border-0 mb-4 shadow-md">
        <div className="card-body p-0">
          <ul className="nav nav-tabs border-bottom">
            <li className="nav-item">
              <a 
                href="#nap-tien" 
                className={`nav-link ${activeTab === 'nap-tien' ? 'active' : ''}`} 
                onClick={() => handleTabChange('nap-tien')}
              >
                NẠP TIỀN
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className="card-body py-1">
          <div className="tab-content">
            {activeTab === 'nap-tien' && (
              <div id="nap-tien">
                <div className={`tab-pane fade ${activeTab === "nap-tien" ? "show active" : ""}`} id="atm">
                  <div className="row">
                    <div className="col">
                      <div className="mb-3 w-100">
                        {_app.length > 0 ? (
                          <div className="form-container">
                            <Combobox
                              data={_app}
                              textField="Name_app"
                              valueField="Id_app"
                              value={selectedItem}
                              onChange={handleChange}
                              placeholder="Chọn ứng dụng"
                              filter="contains"
                              className="combobox"
                            />
                            {errors.selectedItem && <div className="text-danger">{errors.selectedItem}</div>}
                            <input
                              type="text"
                              value={appId}
                              onChange={handleAppIdChange}
                              placeholder="ID app của bạn"
                              className="form-control mt-3"
                            />
                            {errors.appId && <div className="text-danger">{errors.appId}</div>}
                            <input
                              type="text"
                              value={formatNumber(customText)}
                              onChange={handleTextChange}
                              placeholder="Nhập số tiền cần nạp"
                              className="form-control mt-3"
                            />
                            {errors.customText && <div className="text-danger">{errors.customText}</div>}
                            <input
                              type="text"
                              value={formatNumber(coint)}
                              className="form-control mt-3"
                              readOnly
                            />
                            {errors.coint && <div className="text-danger">{errors.coint}</div>}
                            <button
                              type="button"
                              onClick={handleSubmit}
                              className="custom-button m-3 text-center justify-content-center"
                              style={{maxWidth:"115px"}}
                            >
                              <i className="fas fa-paper-plane mx-1"></i> Gửi
                            </button>
                          </div>
                        ) : (
                          <p>No apps available</p>
                        )}
                        <TransactionHistory userName={user?.data[0]?.UserName} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
