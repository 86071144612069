// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  position: relative;
  width: 100%;
}

.selected {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
  background: #ffffff;
  transition: border-color 0.3s;
}

/* Change border color on focus */
.selected:focus {
  border-color: #4E3B31;
  outline: none;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

/* Styling for each option */
.options-list li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

/* Change background color on hover */
.options-list li:hover {
  background: #f0f0f0;
}

/* Style for option icons */
.option-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/style/ListTypeCard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA,iCAAiC;AACjC;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;AACf;;AAEA,4BAA4B;AAC5B;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA,qCAAqC;AACrC;EACE,mBAAmB;AACrB;;AAEA,2BAA2B;AAC3B;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".custom-select {\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.selected {\r\n  padding: 10px;\r\n  border: 1px solid #ced4da;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n  background: #ffffff;\r\n  transition: border-color 0.3s;\r\n}\r\n\r\n/* Change border color on focus */\r\n.selected:focus {\r\n  border-color: #4E3B31;\r\n  outline: none;\r\n}\r\n\r\n.options-list {\r\n  position: absolute;\r\n  top: 100%;\r\n  left: 0;\r\n  right: 0;\r\n  background: white;\r\n  border: 1px solid #ced4da;\r\n  border-radius: 5px;\r\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\r\n  max-height: 200px;\r\n  overflow-y: auto;\r\n  z-index: 1000;\r\n}\r\n\r\n/* Styling for each option */\r\n.options-list li {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 10px;\r\n  cursor: pointer;\r\n  transition: background 0.3s;\r\n}\r\n\r\n/* Change background color on hover */\r\n.options-list li:hover {\r\n  background: #f0f0f0;\r\n}\r\n\r\n/* Style for option icons */\r\n.option-icon {\r\n  width: 24px;\r\n  height: 24px;\r\n  margin-right: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
