import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { UserContext } from '../App';

const Summary = (props) => {
    const { user } = useContext(UserContext);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(API_BASE_URL + '/summaryCommissions', {
                params: {
                    username: user?.data[0]?.UserName,
                    id_type: user?.data[0]?.id_type,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const { transactions } = res.data;
            setTransactions(transactions);
            setFilteredTransactions(transactions);
            console.log(transactions);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTransactions(transactions);
        } else {
            setFilteredTransactions(
                transactions.filter((item) =>
                    item.UserName.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }
    }, [searchQuery, transactions]);

    const handleClickUpdateCommission = async (userName) => {
        try {
            setLoading(true);
            await axios.post(API_BASE_URL + '/update_commission', {
                username: userName,
            });
            setLoading(false);
            const confirm = window.confirm('Đã thanh toán hoa hồng cho ' + userName);
            if (confirm) {
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const formatSurplus = (value) => {
        return value.toLocaleString('vi-VN');
    };

    // Dynamically extract headers from the first transaction object
    const extractHeaders = (data) => {
        const excludeHeaders = ['UserName', 'totalMoney'];
        return Object.keys(data)
            .filter(key => !excludeHeaders.includes(key)) // Optionally exclude certain keys
            .concat(['UserName', 'totalMoney']);  // Ensure 'UserName' and 'totalMoney' are at the start
    };

    const renderTableHeaders = () => {
        const headers = extractHeaders(filteredTransactions[0] || {});  // Extract headers dynamically
        return (
            <>
                <th  key="UserName">TÊN TÀI KHOẢN</th>
                <th  key="totalMoney">TỔNG TIỀN</th>
                {headers.map((header) => {
                if (header === 'UserName' || header === 'totalMoney') {
                    return null;  // Skip rendering these headers
                }
                return (
                    <th key={header}>
                        {header.toUpperCase()} {/* Display the header in uppercase */}
                    </th>
                );
            })}
            <th></th>
            </>
        );
    };

    // Dynamically render table cells based on headers
    const renderTableData = (sMoney) => {
        const headers = extractHeaders(filteredTransactions[0] || {});
        return (
            <>
                <td>{sMoney.UserName}</td>
                <td>{formatSurplus(sMoney.totalMoney)}</td>
                {headers.map((header, index) => {
                if (header === 'UserName' || header === 'totalMoney') {
                    return null;  // Skip rendering these fields
                }
                return (
                    <td  key={index}>
                        {formatSurplus(sMoney[header])}
                    </td>
                );
            })}
            </>
        );
    };

    if (loading) {
        return (
            <div className="loading-container">
                <ReactLoading type="spin" color="#000" />
            </div>
        );
    }

    return (
        <div className="container">
            {/* Search Input */}
            <div className="my-4">
                <label htmlFor="searchQuery">Tìm kiếm Tài khoản:</label>
                <input
                    id="searchQuery"
                    type="text"
                    className="form-control"
                    placeholder="Nhập tên tài khoản"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Total Money Table */}
            <h2 className="text-center my-4">Tổng tiền nạp thanh toán hoa hồng</h2>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            {renderTableHeaders()}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTransactions.map((sMoney, index) => (
                            <tr key={index}>
                                {renderTableData(sMoney)}
                                <td>
                                    {user?.data[0]?.id_type === '1' && (
                                        <input
                                            type="button"
                                            value="Thanh Toán"
                                            className="button"
                                            onClick={() => handleClickUpdateCommission(sMoney.UserName)}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Summary;
