import React, { useState, useEffect, useRef } from 'react';

const gradients = [
  'linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700)',
  'linear-gradient(135deg, #F9D29D, #F6B873, #FF7E39)',
  'linear-gradient(135deg, #DFF5C9, #BCE6A8, #8BCB73)',
  'linear-gradient(135deg, #FDE2E4, #FAB1A0, #FF7676)',
  'linear-gradient(135deg, #D2EFFF, #A1D8FF, #70BAFF)',
  'linear-gradient(135deg, #f44034, #f8836f, #ffbc93)'
];

const gradientToLightColor = {
  'linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700)': '#FFFBE5',
  'linear-gradient(135deg, #F9D29D, #F6B873, #FF7E39)': '#FDE6D3',
  'linear-gradient(135deg, #DFF5C9, #BCE6A8, #8BCB73)': '#EFFAE5',
  'linear-gradient(135deg, #FDE2E4, #FAB1A0, #FF7676)': '#FEEDEE',
  'linear-gradient(135deg, #D2EFFF, #A1D8FF, #70BAFF)': '#EAF9FF',
  'linear-gradient(135deg, #f44034, #f8836f, #ffbc93)': '#ffe0e1',
};

const ColorPicker = ({ setBgColor }) => {
  const [bgColor, setBgColorLocal] = useState(() => {
    return localStorage.getItem('bgColor') || gradients[0];
  });
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const handleColorSelect = (gradient) => {
    setBgColorLocal(gradient);
    setBgColor(gradient); 
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.background = bgColor;
    const lightColor = gradientToLightColor[bgColor] || '#FFFBE5';
  
    const appContainer = document.querySelector('.app_container');
    if (appContainer) {
      appContainer.style.background = lightColor;
    }
  
    localStorage.setItem('bgColor', bgColor);
  }, [bgColor]);
  

  return (
    <div ref={containerRef} style={styles.container}>
      <div style={styles.toggleButton} onClick={() => setIsOpen(!isOpen)}>
        🎨
      </div>
      {isOpen && (
        <div style={styles.gradientList}>
          {gradients.map((gradient, index) => (
            <div
              key={index}
              style={{ ...styles.gradientItem, background: gradient }}
              onClick={() => handleColorSelect(gradient)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    zIndex: 1000,
  },
  toggleButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  },
  gradientList: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  },
  gradientItem: {
    width: '100px',
    height: '40px',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
  },
};

export default ColorPicker;
