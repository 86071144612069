import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../style/Footer.css';

const Footer = ({ bgColor, className }) => {
  const location = useLocation();

  return (
    <div className={`footer ${className}`} style={{ background: bgColor }}>
      <Link 
        to="/" 
        className={`footer-item ${location.pathname === '/' ? 'active' : ''}`}
        style={{ background: location.pathname === '/' ? bgColor : 'transparent' }}
      >
        <span><i className="fa-solid fa-wallet"></i> Nạp tiền vào ví</span>
      </Link>
      <Link 
        to="/rechargeSupme" 
        className={`footer-item ${location.pathname === '/rechargeSupme' ? 'active' : ''}`}
        style={{ background: location.pathname === '/rechargeSupme' ? bgColor : 'transparent' }}
      >
        <span><i className="fa-solid fa-cart-shopping"></i> Nạp app</span>
      </Link>
      <Link 
        to="/withdrawmoney" 
        className={`footer-item ${location.pathname === '/withdrawmoney' ? 'active' : ''}`}
        style={{ background: location.pathname === '/withdrawmoney' ? bgColor : 'transparent' }}
      >
        <span><i className="fa-solid fa-store"></i> Rút tiền</span>
      </Link>
    </div>
  );
};

export default Footer;
