// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/Footer.css */
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }
  
  .footer-item svg {
    font-size: 20px;
    margin-bottom: 4px;
  }
  
  .footer-item span {
    font-size: 12px;
  }
  
  .footer-item.active {
    text-decoration: underline;
    text-decoration-color: #4E3B31;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/Footer.css"],"names":[],"mappings":"AAAA,0BAA0B;;EAExB;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,0BAA0B;IAC1B,8BAA8B;IAC9B,iBAAiB;EACnB","sourcesContent":["/* components/Footer.css */\r\n  \r\n  .footer-item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    font-size: 15px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .footer-item svg {\r\n    font-size: 20px;\r\n    margin-bottom: 4px;\r\n  }\r\n  \r\n  .footer-item span {\r\n    font-size: 12px;\r\n  }\r\n  \r\n  .footer-item.active {\r\n    text-decoration: underline;\r\n    text-decoration-color: #4E3B31;\r\n    font-weight: bold;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
