import React, { useState, useContext, useEffect, Fragment } from "react";
import "../style/rechargeSupme.css";
import { UserContext } from '../App';
import axios from "axios";
import { Combobox } from 'react-widgets';
import ReactLoading from 'react-loading';
import 'react-widgets/dist/css/react-widgets.css';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ContactAd(props) {
    const [content, setContent] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]); 
    const [loading, setLoading] = useState(true);
    const { user } = useContext(UserContext);
    const [_app, setApp] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [newPrice, setNewPrice] = useState('');
    const [activeTab, setActiveTab] = useState('price');
    const [isSaving, setIsSaving] = useState(false);
    const [_account, setAccount] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedAccount, setEditedAccount] = useState(null);
    const [updatedUserName, setUpdatedUserName] = useState('');
    const [updatedSurplus, setUpdatedSurplus] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const modules = {
        toolbar: [
          [{ header: [1, 2, 3, false] }], // Tùy chọn header
          ["bold", "italic", "underline"], // Định dạng văn bản
          [{ color: [] }, { background: [] }], // Thay đổi màu chữ và màu nền
          [{ list: "ordered" }, { list: "bullet" }], // Danh sách
          ["link", "image"], // Liên kết và ảnh
          ["clean"], // Xóa định dạng
        ],
      };
    
      const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "color",
        "background",
        "list",
        "bullet",
        "link",
        "image",
      ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [appRes, accountRes] = await Promise.all([
                    axios.get(API_BASE_URL + '/allapp').catch(error => {
                        console.error('Error fetching app data:', error);
                        return { data: [] };
                    }),
                    axios.get(API_BASE_URL + '/accounts').catch(error => {
                        console.error('Error fetching account data:', error);
                        return { data: [] };
                    }),
                ]);

                setApp(appRes.data || []);
                setAccount(accountRes.data?.accouts || []);
                setFilteredAccounts(accountRes.data?.accouts || []);
            } catch (error) {
                console.error('Error during data fetch:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [API_BASE_URL]);
    const handleSave = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/savecontent`, { 
                
                content:content });
            alert( response.data.message);
        } catch (error) {
            console.error("Error saving content:", error);
        }
    };
    const handleSearch = (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        const filtered = _account.filter((account) =>
            account.UserName.toLowerCase().includes(term.toLowerCase()) ||
            account.surplus.toString().includes(term)
        );

        setFilteredAccounts(filtered);
    };

    const handleEditClick = (account) => {
        setEditedAccount(account);
        setUpdatedUserName(account.UserName);
        setUpdatedSurplus(account.surplus);
        setOpenDialog(true);
    };

    const handleSaveChanges = async () => {
        if (!editedAccount) return;

        setIsSaving(true);
        try {
            const res = await axios.post(`${API_BASE_URL}/update_surplus`, {
              username: updatedUserName,
              surplus: updatedSurplus.replace('.',''),
              isReset:0,
            });
            console.log('Account updated:', res.data);
            setAccount(_account.map((ac) =>
                ac.username === editedAccount.useContext ? { ...ac, UserName: updatedUserName, surplus: updatedSurplus } : ac
            ));

            setOpenDialog(false);
        } catch (error) {
            console.error('Error updating account:', error);
        } finally {
            setIsSaving(false);
        }
    };
    const handleResetChanges = async () => {
      if (!editedAccount) return;

      setIsSaving(true);
      try {
          const res = await axios.post(`${API_BASE_URL}/update_surplus`, {
            username: updatedUserName,
            surplus: updatedSurplus,
            isReset:1,
          });
          console.log('Account updated:', res.data);
          alert( res.data.newp);

          setOpenDialog(false);
      } catch (error) {
          console.error('Error updating account:', error);
      } finally {
          setIsSaving(false);
      }
  };

    const handleSurplusChange = (event) => {
        // Lấy giá trị nhập vào
        let value = event.target.value;

        // Loại bỏ tất cả ký tự không phải số và dấu phân cách
        value = value.replace(/[^\d]/g, '');

        // Định dạng số với phân cách phần nghìn
        const formattedValue = new Intl.NumberFormat('vi-VN').format(value);

        // Cập nhật giá trị đã định dạng vào state
        setUpdatedSurplus(formattedValue);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'price':
                return (
                    <div key="price" className="tab-content">
                        {loading ? (
                            <ReactLoading
                                type="spin"
                                color="#4E3B31"
                                className="loading-spinner"
                            />
                        ) : (
                            <>
                                <h2>Cập nhật giá mới</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Chọn app</label>
                                        <Combobox
                                            data={_app}
                                            textField="Name_app"
                                            valueField="Id_app"
                                            value={selectedItem}
                                            onChange={(value) => {
                                                setSelectedItem(value);
                                                setNewPrice(value.price);
                                            }}
                                            placeholder="Select Application"
                                            filter="contains"
                                        />
                                    </div>
                                    {selectedItem && (
                                        <>
                                            <div className="col-md-3">
                                                <label>Giá mới</label>
                                                <input
                                                    type="number"
                                                    value={newPrice}
                                                    onChange={(e) => setNewPrice(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <button
                                                    className="btn btn-primary"
                                                    disabled={isSaving}
                                                >
                                                    {isSaving ? "Vui lòng đợi..." : "Cập nhật"}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                );
            case 'account':
                return (
                    <div key="account" className="tab-content">
                        <h2>Quản lý tài khoản</h2>
                        {/* Thanh tìm kiếm */}
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Tìm kiếm tài khoản..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        {/* Bảng tài khoản */}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Tên tài khoản</th>
                                    <th>Số dư</th>
                                    <th>Chỉnh sửa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAccounts.map((account) => (
                                    <tr key={account.UserName}>
                                        <td>{account.UserName}</td>
                                        <td>{new Intl.NumberFormat('vi-VN').format(account.surplus)}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(account)} className="btn btn-warning">
                                                Sửa
                                            </button>
                                       
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
                case 'notify':
                return (
                  <div key="account" className="tab-content">
                    <ReactQuill 
                     modules={modules}
                     formats={formats}
                     theme="snow"
                    value={content} onChange={setContent} />
                    <button onClick={handleSave}>Lưu</button>
                  </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="form-container">
            <div className="tabs">
                <button
                    onClick={() => setActiveTab('price')}
                    className={`tab-button ${activeTab === 'price' ? 'active' : ''}`}
                >
                    Price
                </button>
                <button
                    onClick={() => setActiveTab('account')}
                    className={`tab-button ${activeTab === 'account' ? 'active' : ''}`}
                >
                    Account
                </button>
                <button
                    onClick={() => setActiveTab('notify')}
                    className={`tab-button ${activeTab === 'notify' ? 'active' : ''}`}
                >
                    Thông báo
                </button>
            </div>
            {renderContent()}

            {/* Dialog for editing accounts */}
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <i className="fas fa-edit"></i> Chỉnh sửa tài khoản
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: "20px" }}>
                        Vui lòng nhập thông tin tài khoản cần chỉnh sửa.
                    </DialogContentText>
                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <TextField disabled={true}
                            fullWidth
                            label="Tên tài khoản"
                            variant="outlined"
                            value={updatedUserName}
                            onChange={(e) => setUpdatedUserName(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="Số dư"
                            variant="outlined"
                            type="text"
                            value={updatedSurplus}
                            onChange={handleSurplusChange} // Sử dụng hàm xử lý thay đổi cho số dư
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenDialog(false)}
                        color="error"
                        variant="outlined"
                    >
                        Hủy
                    </Button>
                    <Button
                        onClick={handleSaveChanges}
                        color="primary"
                        variant="contained"
                        disabled={isSaving}
                    >
                        {isSaving ? "Đang lưu..." : "Lưu"}
                    </Button>
                    <Button
                        onClick={handleResetChanges}
                        color="primary"
                        variant="contained"
                        disabled={isSaving}
                    >
                        {isSaving ? "Đang lưu..." : "Reset mật khẩu"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
