import React, { useEffect, useState, createContext, useCallback } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import AccountInfo from "./pages/AccountInfo";
import RechargeSupme from "./pages/RechargeSupme";
import WithDrawMoney from "./pages/WithDrawMoney";
import TransferMoney from "./pages/TransferMoney";
import Collaborator from "./pages/Collaborator";
import Agency from "./pages/Agency";
import ContactAd from "./pages/ContactAd";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RechargeCoinSupme from "./pages/RechargeCoinSupme";
import ScratchCard from "./pages/ScratchCard";
import Summary from "./pages/Sumary";
import "./App.css";
import Cookies from "js-cookie";
import axios from "axios";
import Footer from "./components/Footer";
import ColorPicker from "./components/ColorPicker";

// Styling using styled-components
const Content = styled.div`
  margin: ${({ isOpen }) => (isOpen ? '25px 25px 25px 275px' : '0')};
  transition: margin-left 350ms;
  padding: ${({ isLoggedIn }) => (isLoggedIn ? '20px' : '0')};
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledFooter = styled(Footer)`
  display: flex;
  justify-content: space-around;
  background: linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700);
  padding: 10px 0;
  margin: 0 10px;
  position: fixed;
  bottom: 0;
  width: ${({ isOpen }) => (isOpen ? 'calc(100% - 315px)' : 'calc(100% - 20px)')} !important;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
`;

// Context to share user data globally
export const UserContext = createContext();

// Utility function to get the background color from localStorage
const getBackgroundFromLocalStorage = () => {
  const savedBgColor = localStorage.getItem('bgColor') || 'linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700)';
  const gradientToLightColor = {
    'linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700)': '#FFF8D7',
    'linear-gradient(135deg, #F9D29D, #F6B873, #FF7E39)': '#FDE6D3',
    'linear-gradient(135deg, #DFF5C9, #BCE6A8, #8BCB73)': '#EFFAE5',
    'linear-gradient(135deg, #FDE2E4, #FAB1A0, #FF7676)': '#FEEDEE',
    'linear-gradient(135deg, #D2EFFF, #A1D8FF, #70BAFF)': '#EAF9FF',
    'linear-gradient(135deg, #f44034, #f8836f, #ffbc93)': '#ffe0e1'
  };
  return { savedBgColor, lightColor: gradientToLightColor[savedBgColor] || '#FFFBE5' };
};

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [bgColor, setBgColor] = useState(localStorage.getItem('bgColor') || 'linear-gradient(135deg, #FFF7A1, #FFEBA1, #FFD700)');

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const toggleSidebar = () => setSidebarOpen(prev => !prev);

  const handleLogin = useCallback((userData) => {
    setIsLoggedIn(true);
    setUser(userData);
    setSidebarOpen(true);
  }, []);

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setUser(null);
    Cookies.remove('key');
  }, []);

  // Check login state when component is mounted
  useEffect(() => {
    const checkLoginState = async () => {
      const key = Cookies.get('key');
      if (key) {
        const [username, password] = key.split('_');
        try {
          const res = await axios.post(API_BASE_URL + '/login', { username, password });
          if (res.data.length > 0) handleLogin(res);
        } catch (err) {
          console.error('Error during re-login:', err);
        }
      }
    };
    checkLoginState();
  }, [handleLogin]);

  // Apply background color from localStorage
  useEffect(() => {
    const { savedBgColor, lightColor } = getBackgroundFromLocalStorage();
    document.documentElement.style.background = savedBgColor;
    const appContainer = document.querySelector('.app_container');
    if (appContainer) appContainer.style.background = lightColor;
  }, []);

  return (
    <div className="app_container">
      <ColorPicker setBgColor={setBgColor} />
      <UserContext.Provider value={{ user, setUser }}>
        {isLoggedIn ? (
          <Router>
            <Sidebar isOpen={sidebarOpen} isLoggedIn={isLoggedIn} toggleSidebar={toggleSidebar} bgColor={bgColor} />
            <Content isOpen={sidebarOpen} isLoggedIn={isLoggedIn}>
              <Routes>
                <Route path="/" element={<RechargeCoinSupme />} />
                <Route path="/accountInfo" element={<AccountInfo />} />
                <Route path="/rechargeSupme" element={<RechargeSupme />} />
                <Route path="/withDrawMoney" element={<WithDrawMoney />} />
                <Route path="/transfermoney" element={<TransferMoney />} />
                <Route path="/agency" element={<Agency />} />
                <Route path="/setting" element={<ContactAd />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/collaborator" element={<Collaborator />} />
                <Route path="/scratchcard" element={<ScratchCard />} />
              </Routes>
              <StyledFooter isOpen={sidebarOpen} bgColor={bgColor} />
            </Content>
          </Router>
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<Login onLogin={handleLogin} />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </Router>
        )}
      </UserContext.Provider>
    </div>
  );
}

export default App;
