// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logOut {
    color: #e1e9fc;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    &:hover {
        background: #252831;
        border-left: 4px solid #632ce4;
        cursor: pointer;
    }
}
.bnrnRg {
    justify-content: flex-start;
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/style/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf;QACI,mBAAmB;QACnB,8BAA8B;QAC9B,eAAe;IACnB;AACJ;AACA;IACI,2BAA2B;IAC3B,iBAAiB;AACrB","sourcesContent":[".logOut {\r\n    color: #e1e9fc;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    padding: 20px;\r\n    list-style: none;\r\n    height: 60px;\r\n    text-decoration: none;\r\n    font-size: 18px;\r\n    &:hover {\r\n        background: #252831;\r\n        border-left: 4px solid #632ce4;\r\n        cursor: pointer;\r\n    }\r\n}\r\n.bnrnRg {\r\n    justify-content: flex-start;\r\n    margin-left: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
